#footer {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: 0px;
}

#footer svg {
  position: relative;
  width: 100%;
  bottom: -7px;
}

#footer .footer-text {
  text-align: center;
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  height: 80px;
}

#footer .footer-text .icons {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 750px) {
  #footer .footer-text .icons {
    display: none;
  }
}

#footer .footer-text .icons button {
  border: 1px solid #84c69b;
  background-color: transparent;
  border-radius: 50%;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

#footer .footer-text .icons button:hover > svg {
  color: #84c69b;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

#footer .footer-text .icons button svg {
  color: #2f8886;
  margin-bottom: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

#footer .footer-text p {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  color: rgb(187, 187, 187);
  font-weight: 300;
}
