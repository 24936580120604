#main .main-header .arrow {
  color: #84c69b;
  display: inline;
  margin: auto;
  font-size: 100px;
  padding-right: 20px;
  z-index: 1;
  cursor: pointer;
}

#main .main-header .arrow svg {
  transition: color 1s ease;
  -webkit-transition: color 1s ease;
  -ms-transition: color 1s ease;
}

#main .main-header .arrow svg:hover {
  color: #2f8886;
  transition: color 1s ease;
  -webkit-transition: color 1s ease;
  -ms-transition: color 1s ease;
}

@media (max-width: 868px) {
  #main .main-header .arrow {
    display: none;
  }
}

/* content */

#main .wave {
  display: block;
  position: relative;
}

#main .top-wave {
  margin-top: -110px;
}

@media (max-width: 868px) {
  #main .top-wave {
    margin-top: 0px;
  }
}

#main .content {
  background-color: #1a2f4b;
  margin-top: 10px;
}

#main .content .flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

#main .content .flex .flex-div {
  padding: 5px;
  width: 90%;
  margin: 20px 5%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
}

#main .content .flex .flex-div div {
  width: 50%;
  height: 100%;
  min-height: 500px;
}

#main .content .flex .flex-div .text {
  padding: 50px;
  padding-right: 120px;
}

#main .content .flex .flex-div .images {
  display: flex;
  justify-content: center;
  align-items: center;
}

#main .content .flex .flex-div .images img,
#main .content .flex .flex-div .images link {
  width: 90%;
  height: 50%;
}

@media (max-width: 1000px) {
  #main .content .flex .flex-div {
    width: 95%;
    margin: 10px 2.5%;
  }

  #main .content .flex .flex-reverse {
    flex-direction: column-reverse;
  }

  #main .content .flex .flex-forward {
    flex-direction: column;
  }

  #main .content .flex .flex-div div {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  #main .content .flex .flex-div .text {
    text-align: center;
    padding-right: 50px;
  }

  #main .content .flex .flex-div .images {
    min-height: 0px;
    padding: 5px;
  }

  #main .content .flex .flex-div .images img {
    width: 100%;
  }
}

#main .content .flex .flex-div .text h3 {
  color: white;
  font-size: 30px;
  font-weight: 700;
}

#main .content .flex .flex-div .text p {
  color: rgb(187, 187, 187);
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 1400px) and (min-width: 1000px) {
  #main .content .flex .flex-div .text {
    padding-right: 50px;
  }

  #main .content .flex .flex-div .text h3 {
    font-size: 25px;
  }

  #main .content .flex .flex-div .text p {
    color: rgb(187, 187, 187);
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  #main .content .flex .flex-div .text {
    padding: 10px;
  }

  #main .content .flex .flex-div .text h3 {
    font-size: 25px;
  }

  #main .content .flex .flex-div .text p {
    color: rgb(187, 187, 187);
    font-size: 14px;
  }
}

/* practice */

#main .practice {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
}

#main .practice h2 {
  color: white;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

#main .practice h3 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  opacity: 0.8;
}

@media (max-width: 868px) {
  #main .practice {
    padding: 10px;
  }
  #main .practice h2 {
    font-size: 35px;
  }

  #main .practice h3 {
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  #main .practice h2 {
    font-size: 30px;
  }

  #main .practice h3 {
    font-size: 13px;
  }
}

@media (max-width: 450px) {
  #main .practice h2 {
    font-size: 25px;
  }

  #main .practice h3 {
    font-size: 12px;
  }
}

#main .practice .discord {
  width: 60%;
  margin: auto;
  height: 400px;
  border-radius: 20px;
  border: 5px solid #28475c;
  background-color: #36393f;
  position: relative;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  #main .practice .discord {
    width: 80%;
  }
}

@media (max-width: 800px) {
  #main .practice .discord {
    width: 95%;
  }
}

@media (max-width: 500px) {
  #main .practice .discord {
    width: 100%;
  }
}

#main .practice .discord #start-test {
  border: none;
  background-color: #2f8886;
  height: 80px;
  width: 200px;
  font-size: 25px;
  font-weight: 500;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 1s linear;
  -webkit-transition: all 1s linear;
  -ms-transition: all 1s linear;
  z-index: 1;
}

#main .practice .discord #reset-test {
  border: none;
  background-color: #b82e2e;
  height: 80px;
  width: 200px;
  font-size: 25px;
  font-weight: 500;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  opacity: 0;
  z-index: 1;
}

#main .practice .discord #caught-title {
  text-align: center;
  top: 200px;
  height: 20px;
  width: 100%;
  position: absolute;
  font-size: 25px;
  color: white;
  opacity: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  z-index: 1;
}

#main .practice .discord #caught-message {
  text-align: center;
  top: 240px;
  height: 20px;
  width: 100%;
  position: absolute;
  font-size: 16px;
  color: white;
  opacity: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  z-index: 1;
}

#main .practice .discord #opacity-changer {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 17px;
  justify-content: space-evenly;
}

#main .practice .discord .messages {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  height: 85%;
  width: 100%;
}

#main .practice .discord .messages .message {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  #main .practice .discord .messages {
    padding: 0px;
  }

  #main .practice .discord .messages .message {
    zoom: 0.8;
  }
}

#main .practice .discord .messages .message > div {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#main .practice .discord .messages .profile-picture img {
  border-radius: 50%;
  height: 50px;
}

#main .practice .discord .messages .username {
  color: white;
  font-size: 16px;
  margin-top: 1px;
  margin-left: 10px;
}

#main .practice .discord .messages .verified {
  height: 15px;
  margin-top: 5px;
  margin-left: 5px;
}

#main .practice .discord .messages .time {
  color: rgb(97, 97, 97);
  font-size: 11px;
  margin-top: 6px;
  margin-left: 10px;
}

#main .practice .discord .messages .embed {
  background-color: #2f3136;
  font-size: 11px;
  top: 40px;
  left: 75px;
  border-left: 5px solid #424981;
  border-radius: 5px;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 10px;
}

#main .practice .discord .messages .embed .text {
  height: 100%;
  width: 82.5%;
}

#main .practice .discord .messages .embed .text .title {
  color: white;
  font-size: 15px;
  top: 15px;
  left: 15px;
  font-weight: 600;
  margin: 10px;
}

#main .practice .discord .messages .embed .text .description {
  color: white;
  font-size: 12px;
  top: 45px;
  left: 15px;
  margin: 0 10px;
}

#main .practice .discord .messages .embed .text .footer {
  color: white;
  font-size: 10px;
  bottom: 10px;
  left: 15px;
  margin: 10px;
}

#main .practice .discord .messages .embed .thumbnail {
  height: 100%;
  margin: 5px;
}

#main .practice .discord .messages .embed .thumbnail img {
  height: 70px;
}

#main .practice .discord .messages #message-content {
  color: white;
  font-size: 15px;
  width: 100%;
  margin-left: 10px;
}

#main .practice .discord form {
  background-color: #40454a;
  border-radius: 8px;
  height: 40px;
  bottom: 25px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 500px) {
  #main .practice .discord form .hide-phone {
    display: none;
  }
}

#main .practice .discord form div {
  height: 100%;
  width: 40px;
  /* border: 1px solid blue; */
}

#main .practice .discord form div svg {
  color: #b9babf;
  width: 24px;
  height: 24px;
  margin-left: 11px;
  margin-top: 7px;
  margin-right: 10px;
}

#main .practice .discord form input {
  background-color: transparent;
  margin-left: 5px;
  height: 100%;
  border: none;
  width: 100%;
  outline: none;
  color: white;

  font-size: 14px;
}

#main .practice .discord #loading-dots {
  bottom: 12px;
  left: 20px;
  height: 10px;
  position: absolute;
  display: inline-block;
}

#main .practice .discord #loading-dots img {
  margin-top: 4px;
  height: 8px;
  float: left;
}

#main .practice .discord #loading-dots p {
  margin-left: 5px;
  font-size: 12px;
  color: white;
  float: right;
}
