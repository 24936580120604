.navbar {
  position: fixed;
  width: 100%;
  z-index: 2;
  padding: 25px 0;
  transition: all 0.3s ease;
}

.navbar.sticky {
  background: #1a2f4b;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

.navbar .content {
  max-width: 1250px;
  margin: auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo a {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: underline #2f8886 solid 6px;
  transition: text-decoration 2s ease-out;
  -webkit-transition: text-decoration 2s ease-out;
  -ms-transition: text-decoration 2s ease-out;
}

.navbar .logo a:hover {
  text-decoration: underline #84c69b solid 6px;
  transition: text-decoration 2s ease-out;
  -webkit-transition: text-decoration 2s ease-out;
  -ms-transition: text-decoration 2s ease-out;
}

.navbar .menu-list {
  display: inline-flex;
}

.menu-list li {
  list-style: none;
}

.menu-list li a {
  background-color: #28475c;
  display: inline-block;
  border: none;
  border-radius: 12px;
  padding: 10px 15px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-list li a:hover {
  color: #84c69b;
}

.about {
  padding: 30px 0;
}

.about .title {
  font-size: 38px;
  font-weight: 700;
}

.about p {
  padding-top: 20px;
  text-align: justify;
}

.icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.menu-list .cancel-btn {
  position: absolute;
  right: 30px;
  top: 20px;
}

@media (max-width: 1230px) {
  .navbar .content {
    padding: 0 60px;
  }
}

@media (max-width: 1100px) {
  .navbar .content {
    padding: 0 40px;
  }
}

@media (max-width: 900px) {
  .navbar .content {
    padding: 0 30px;
  }
}

@media (max-width: 868px) {
  body.disabled {
    overflow: hidden;
  }

  .icon {
    display: block;
  }

  .icon.hide {
    display: none;
  }

  .navbar .menu-list {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    left: -100%;
    top: 0px;
    display: block;
    padding: 40px 0;
    text-align: center;
    background: #222;
    transition: all 0.3s ease;
  }

  .navbar.show .menu-list {
    left: 0%;
  }

  .navbar .menu-list li {
    margin-top: 45px;
  }

  .navbar .menu-list li a {
    font-size: 23px;
    margin-left: -100%;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .navbar.show .menu-list li a {
    margin-left: 0px;
  }
}

@media (max-width: 380px) {
  .navbar .logo a {
    font-size: 27px;
  }
}

.active {
  background-color: #2b7c7b !important; /* #2f8886 */
}
