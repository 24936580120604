@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background: #141e30;
  background: -webkit-linear-gradient(to left, #243b55, #141e30);
  background: linear-gradient(to left, #243b55, #141e30);
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#main {
  position: relative;
  margin-top: 120px;
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}

/* @media (max-width: 868px) {
  #main {
    padding: 10px;
  }
} */

.hidden {
  display: none !important;
}
